import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ContactPageDataType } from "../../types/pages";

export default function Contact({ data }: PageProps<ContactPageDataType>) {
  const { storeWebsiteData, site } = data;
  const {
    store_address,
    store_description,
    store_contact_email,
    store_phone,
    store_logo
  } = storeWebsiteData.store_primary_settings.store_ui;
  const {
    store_facebook,
    store_instagram,
    store_linkedin,
    store_snapchat,
    store_tiktok,
    store_twitter,
    store_youtube
  } = storeWebsiteData.store_primary_settings.store_social_media;
  const productDates = {
    first_publication_date: site?.buildTime || (new Date()).toLocaleString(),
    last_publication_date: site?.buildTime || (new Date()).toLocaleString(),
  };

  return (
    <Layout>

      <SEO
        title="Contact"
        description={store_description}
        banner={store_logo || ""}
        pathname="/contact/"
        node={productDates}
      />

      <div className="div container">
        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6">
                <h1 className="h2 text-uppercase mb-0">اتصال</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-lg-end mb-0 px-0 bg-light">
                    <li className="breadcrumb-item">
                      <Link className="text-dark" to="/">الرئسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">اتصال</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 mb-3 mb-md-0" dir="rtl">
                <h2 className="h4">رقم البريد الإلكتروني / الهاتف</h2>
                <div>
                  <strong>رقم الهاتف</strong>:{` `}
                  <a href={`tel://${store_phone}`}>{store_phone}</a>
                </div>
                <div>
                  <strong>بريد إلكتروني</strong>:{` `}
                  <a href={`mailto://${store_contact_email}`}>{store_contact_email}</a>
                </div>
                <div>
                  <strong>عنوان: </strong>{` `}
                  <span>{store_address}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6" dir="rtl">
                <h2 className="h4">الشبكات الاجتماعية</h2>
                <div className="d-flex flex-column">
                  {
                    !!store_twitter && (
                      <a target="_blank" rel="noopener" href={store_twitter}>تويتر</a>
                    )
                  }
                  {
                    !!store_facebook && (
                      <a target="_blank" rel="noopener" href={store_facebook}>الفيسبوك</a>
                    )
                  }
                  {
                    !!store_instagram && (
                      <a target="_blank" rel="noopener" href={store_instagram}>الانستغرام</a>
                    )
                  }
                  {
                    !!store_snapchat && (
                      <a target="_blank" rel="noopener" href={store_snapchat}>سناب شات</a>
                    )
                  }
                  {
                    !!store_tiktok && (
                      <a target="_blank" rel="noopener" href={store_tiktok}>تيك توك</a>
                    )
                  }
                  {
                    !!store_linkedin && (
                      <a target="_blank" rel="noopener" href={store_linkedin}>Linkedin</a>
                    )
                  }
                  {
                    !!store_youtube && (
                      <a target="_blank" rel="noopener" href={store_youtube}>Youtube</a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </Layout>
  );
}

export const pageQuery = graphql`
  query Contact {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
    storeWebsiteData: zzStoreWebsiteDetails {
      id
      store_primary_settings {
        store_social_media {
          store_instagram
          store_facebook
          store_twitter
          store_tiktok
          store_linkedin
          store_youtube
          store_snapchat
        }
        store_ui {
          store_description
          store_phone 
          store_contact_email
          store_address
          store_logo
        }
      }
    }
  }
`;